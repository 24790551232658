.previewWrapper {
  padding: 0px 10px
}

.preview {
  padding: 10px;
  background: #0d1323;
  min-height: 200px;
  border-radius: 4px;
  overflow-y: scroll;
  max-height: 500px;
}

.preview p {
  color: #3155db;
  font-weight: 500
}

.preview span {
  color: #636363;
}

.pre {
  white-space: pre-wrap;       /* Since CSS 2.1 */
  white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
  white-space: -pre-wrap;      /* Opera 4-6 */
  white-space: -o-pre-wrap;    /* Opera 7 */
  word-wrap: break-word;
  font-weight: 500;
  color: #fff
}

.error {
  background: #efadad;
  color: #734d4d
}