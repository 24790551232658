.home_container__1zwgE {
  display: flex;
  justify-content: center;
  align-items: center;
  //height: 100%;
  margin-top:100px;
  text-align: center
}

.home_logoContainer__2tyhC {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -70px
}

.home_logoContainer__2tyhC img {
  width: 120px
}

.home_logoContainer__2tyhC span {
  margin-left: 20px;
  font-size: 80px;
  color: var(--primary);
  font-weight: 500
}

.home_heading__1mUD9 {
  color: #363A3F;
}
.topiclist_link__ZjS9D {
  text-decoration: none;
  color: rgb(49, 85, 220);
  //font-weight: 500;
}

.topiclist_wrapper__3unA3 {
  margin: 10px 0px
}
.editor_editorParent__1nxts {
  position: fixed;
  right: -100vw;
  top:0;
  bottom:0;
  background: #fff;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,.06), 0 4px 5px 0 rgba(0,0,0,.06), 0 1px 9px 3px rgba(0,0,0,.08);
  width: calc(100vw - 240px);
  -webkit-transition: -webkit-transform 0.4s;
  transition: -webkit-transform 0.4s;
  transition: transform 0.4s;
  transition: transform 0.4s, -webkit-transform 0.4s;
  padding: 90px 20px 20px 20px;
  //overflow-y: scroll;
}

.editor_slideIn__28dDN {
  -webkit-transform: translateX(calc(-100vw));
          transform: translateX(calc(-100vw))
}

.editor_button__3rytP {
  outline: 0;
  border: none;
  background: var(--primary);
  padding: 6px 16px;
  color: #fff;
  font-size: 18px;
  border-radius: 4px;
}

.editor_button__3rytP:active {
  background: #1d3694
}
.preview_previewWrapper__2rnUa {
  padding: 0px 10px
}

.preview_preview__32wtO {
  padding: 10px;
  background: #0d1323;
  min-height: 200px;
  border-radius: 4px;
  overflow-y: scroll;
  max-height: 500px;
}

.preview_preview__32wtO p {
  color: #3155db;
  font-weight: 500
}

.preview_preview__32wtO span {
  color: #636363;
}

.preview_pre__3MHJN {
  white-space: pre-wrap;       /* Since CSS 2.1 */
  white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
  white-space: -pre-wrap;      /* Opera 4-6 */
  white-space: -o-pre-wrap;    /* Opera 7 */
  word-wrap: break-word;
  font-weight: 500;
  color: #fff
}

.preview_error__3k2w1 {
  background: #efadad;
  color: #734d4d
}
.monaco_wrapper__y-be6 {
  display: flex
}

.monaco_runButton__2fzHb {
  outline: 0;
  border: none;
  background: var(--primary);
  padding: 8px 20px;
  color: #fff;
  font-size: 18px;
  border-radius: 4px;
}

.monaco_runButton__2fzHb:active {
  background: #1d3694
}

.monaco_monacoWrapper__1zq3J {
  flex-basis: 70%
}

.monaco_previewWrapper__bdzYw {
  flex-basis: 30%;
  word-break: break-word
}

.monaco_buttonGroup__2jVke {
  display: flex;
  padding: 10px 0px;
}

.monaco_buttonGroup__2jVke button {
  margin: 0px 10px
}

.monaco_secondaryButton__2nNBm {
  background: #8e939e
}

.monaco_secondaryButton__2nNBm:active {
  background: #525252
}
